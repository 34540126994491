import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import axios from 'axios'
import logo from '../img/logo2.svg'

export class HomePageTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.content = props.content
    this.contentComponent = props.contentComponent
    this.servers = props.servers

    this.state = {
      mumble: null
    }


  }


  componentDidMount() {
    axios("https://cors.io/?https://api.mumble.com/mumble/cvp.php?token=LSG-8F-70AD3DEB", {
      method: 'GET', 
    }).then(res => {
      this.setState({mumble: res.data.root})
      console.log(this.state.mumble)
    }).catch(error => {
      console.log(error)
    })
  }
  
  render() {
    const PageContent = this.contentComponent || this.Content

    const Mumble = ({mumble}) => {
      if( mumble === null ) {
        return (
          <ul>
          </ul>
        )
      } else {
        return (
          <ul className="mumble">
            {mumble.channels.map(channel => (
              < >
                <li className="channel">{channel.name}
                <ul>
                  {channel.channels.map(channel =>(
                    < >
                    <li className="channel">{channel.name}
                    <ul>
                      {channel.users.map(user =>(
                        <li className="user">{user.name}</li>
                      ))}
                    </ul>
                    </li>
                    </ >
                  ))}
                </ul>
                <ul>
                  {channel.users.map(user =>(
                    <li className="user">{user.name}</li>
                  ))}
                </ul>
                </li>
              </ >

            ))}
          </ul>
        )

      }
    } 
    return (
      < >
        <div className="page-grid__header-container">
          <div className="page-grid__header">
            <div className="page-grid__header__content">
              <img src={logo} className="logo" />
              <p>Founded<br />Feb 15, 2010</p>
            </div>
          </div>
        </div> 
        <PageContent className="page-grid__content content" content={this.content} />
        <div className="page-grid__servers">
          <h2 className="title is-size-3">Currently Hosting</h2>
          <ul className="servers">
          { this.servers.map( server => (
            <li key={server.name}>{server.name}<br /><a href={server.url}>{server.url}</a></li>
          ))}
          </ul>
        </div>
        <div className="page-grid__mumble">
          <h2 className="title is-size-3">Discord Server <a style={{ textDecoration: 'none', marginLeft: '1rem', paddingLeft: '2rem', paddingRight: '2rem', borderRadius: '0px'}} href="https://discord.gg/JPZ8ZEu" className="button is-outlined is-light">Connect</a></h2>
        </div>
      </ >
    )
        }
}

HomePageTemplate.propTypes = {
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const HomePage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <HomePageTemplate
        contentComponent={HTMLContent}
        content={post.html}
        servers={post.frontmatter.servers}
      />
    </Layout>
  )
}

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default HomePage

export const homePageQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        servers {
          name
          url
        }
      }
    }
  }
`
